import PlanCard from '../components/PlanCard/PlanCard'
import { useChangePlan } from '../hooks/useChangePlan'
import { useCallback, useEffect, useState } from 'react'
import { useFetch } from '../hooks/requests'

import { ReactComponent as PageIllustration } from '../images/onboarding-payment.svg'
import { Alert, Button, Radio, Typography, notification } from 'antd'
import useCustomHistory from '../hooks/useCustomHistory'
import { isDebug } from '../lib/debug'
import moment from 'moment'
import { ReactComponent as PriceBadge } from '../images/price-badge.svg'

// const toast = (message) => {
//   notification.info({
//     message: message,
//     // description:
//       // 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
//     placement: 'bottom',
//   })
// }

const errorToast = (message) => {
  notification.error({
    message: message,
    placement: 'bottom',
  })
}

const { Title, Paragraph } = Typography

const features = [
  'Supports ADA & WCAG Compliance',
  'Includes adjustments to fonts, sizing, spacing,  color contrasts, cursors, animations,  and 100+ more',
  'Handles keyboard navigation and screen reader optimization for the blind and motor impaired',
  'Significantly enhance user experience',
  'Improves user interface and website design for the disabled',
]

const plans = {
  month: [
    {
      id: 0,
      title: 'Basic',
      price: 99.99,
      oldPrice: 119.99,
      saveTag: 'SAVE 15%',
      pagesLimit: 1,
      visitorsLimit: 100,
    },
    {
      id: 1,
      title: 'Premium',
      price: 149.99,
      oldPrice: 179.99,
      saveTag: 'SAVE 15%',
      pagesLimit: 10,
      visitorsLimit: 100,
    },
    {
      id: 2,
      title: 'Business',
      price: 229.99,
      oldPrice: 275.99,
      saveTag: 'SAVE 25%',
      pagesLimit: 100,
      visitorsLimit: 100,
    },
    {
      id: 3,
      title: 'Enterprise',
      price: null,
      pagesLimit: 100,
      visitorsLimit: 100,
    },
  ],
  year: [
    {
      id: 0,
      title: 'Basic',
      price: 89.99,
      oldPrice: 199.99,
      saveTag: 'SAVE 20%',
      pagesLimit: 1,
      visitorsLimit: 100,
    },
    {
      id: 1,
      title: 'Premium',
      price: 134.99,
      oldPrice: 164.99,
      saveTag: 'SAVE 20%',
      pagesLimit: 10,
      visitorsLimit: 100,
    },
    {
      id: 2,
      title: 'Business',
      price: 206.99,
      oldPrice: 252.99,
      saveTag: 'SAVE 20%',
      pagesLimit: 100,
      visitorsLimit: 100,
    },
    {
      id: 3,
      title: 'Enterprise',
      price: null,
      pagesLimit: 100,
      visitorsLimit: 100,
    },
  ],
}
function SkipPayment({ updateShop }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Apply Monthly Pricing Plan"
    action={
      <Button
        size="large"
        type="default"
        onClick={() => {
          updateShop({ pricingPlan: 'Premium', pricingPlanPeriod: 'month', onboardingPassedStage: 'payment' })
          // window.location.reload()
        }}
      >
        Skip Payment
      </Button>
    }
  />
}

function FailedPayment() {
  const history = useCustomHistory()
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Show Payment Failed Screen"
    action={
      <Button
        size="large"
        type="default"
        onClick={() => {
          history.push('/billing/failed')
        }}
      >
        Show
      </Button>
    }
  />
}

//  createStripePaymentIntents
function PricingPlans({ usedTrial, shop, updateShop, onboarding }) {
  // change plan for shopify
  const [changePlan] = useChangePlan()
  const [request, { error }] = useFetch()
  const [subscriptionRequest, { /* loading, error, */ data: subscriptionData }] = useFetch()
  const [period, setPeriod] = useState('month')
  const history = useCustomHistory()

  useEffect(() => {
    subscriptionRequest('billing/subscription')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: '{{CLIENT_SECRET}}',
  // }

  // useEffect(() => {
  //   async function getClientSecret(total) {
  //     try {
  //       const { data } = await axios.post(
  //         `/api/payment/create?total=${total * 100}`
  //       );
  //       // All API requests expect amounts to be provided
  //       // in a currency’s smallest unit.

  //       setClientSecret(data.clientSecret);
  //     } catch (error) {
  //       setErrorMsg(error.message);
  //     }
  //   }
  //   getClientSecret(the_amount);
  //   }, [the_amount]);

  const choosePlan = useCallback((plan) => async () => {
    console.log('TCL ~ file: PricingPlans.jsx ~ line 64 ~ choosePlan ~ plan', plan)
    if (shop.platform === 'wordpress') {
      // setShowSubscriptionForm(true)
      const result = await request(
        // `default/createCharge?name=${planName}&annual=${annual ? 'true' : 'false'}`,
        'default/createCheckoutSession',
        'POST',
        {
          priceId: plan.priceId,
        },
      )
      console.log('TCL ~ file: PricingPlans.jsx ~ line 78 ~ choosePlan ~ result', result)
      if (result.url) {
        window.location.href = result.url
      } else {
        errorToast(error?.message || 'Something went wrong. Please try again later.')
      }
    } else {
      changePlan('Premium', plan.annual)
    }
  }, [shop.platform, request, error?.message, changePlan])

  const renewalDate = subscriptionData?.subscription?.current_period_end
    ? moment(subscriptionData?.subscription?.current_period_end * 1000)
    : null

  const activePlanLocalId = (plans.month.find((plan) => plan.title === shop.pricingPlan) || { id: -1 }).id

  const page = {
    leftBlock: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2em', width: '100%' }}>
        <div style={{
          display: 'flex',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            width: 600,
          }}>
            <Title
              style={{
                width: '87%',
              }}
            >
              {onboarding
                ? <>Select your <span className="blue-text">plan.</span></>
                : <>Plan Selection</>
              }
            </Title>
            {onboarding
              ? <>
                <div className="text-14">
                  You will not be charged unless you keep the app longer than the <span className="blue-text">5-day free trial.</span><br/>
                  By picking a plan now, you keep your special pricing for as long as your plan is active with us.
                </div>
              </>
              : <>
                <div className="text-14">
                  SOME TEXT HERE
                </div>
              </>
            }
            <Radio.Group
              value={period}
              onChange={(e) => {setPeriod(e.target.value)}}
              size="small"
              style={{ marginBottom: 16 }}
              buttonStyle="solid"
            >
              <Radio.Button value="month">Monthly</Radio.Button>
              <Radio.Button value="year">Yearly (20% OFF)</Radio.Button>
            </Radio.Group>
          </div>
          <PriceBadge />
        </div>
        <div className="plans-cardList">
          {Array.isArray(plans[period]) &&
            plans[period].length > 0 &&
            plans[period].map((_plan) => {
              const planBackend = shop.plans.find(item => item.title === _plan.title && item.period === period)
              const plan = {
                ..._plan,
                ...(planBackend
                  ? {
                    priceId: planBackend.priceId,
                    trialDays: planBackend.trialDays,
                  }
                  : {}
                ),
              }
              return (
                <PlanCard
                  key={plan.id}
                  {...plan}
                  selected={shop.pricingPlan === plan.title && shop.pricingPlanPeriod === period}
                  pricingPlan={shop.pricingPlan}
                  pricingPlanPeriod={shop.pricingPlanPeriod}
                  activePlanLocalId={activePlanLocalId}
                  onClick={choosePlan(plan)}
                  usedTrial={usedTrial}
                  features={features}
                  period={period}
                  history={history}
                />
              )
            })}
        </div>
        {renewalDate?.isValid() && <Paragraph>
          Next renewal date: <strong>{renewalDate.format('DD/MM/YYYY')}</strong> - <strong>{renewalDate.diff(moment(), 'days')}</strong> days left
        </Paragraph>}
        {(isDebug() && onboarding) && <SkipPayment updateShop={updateShop} />}
        {(isDebug() && onboarding) && <FailedPayment />}
      </div>
    ),
    rightBlock: <PageIllustration />,
  }

  return <div style={{ display: 'flex' }}>
    {page.leftBlock}
  </div>
}

export default PricingPlans
