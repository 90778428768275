import React from 'react'
import './MobilePreview.scss'

const MobilePreview = ({ color }) => {
    return (
      <div className="mobile-preview">
        <svg width="262" height="469" viewBox="0 0 262 469" fill="none" xmlns="http://www.w3.org/2000/svg" data-darkreader-inline-fill="" >
          <rect y="0.526367" width="261.082" height="468.474" rx="6.3165" fill="#EFF4F7" data-darkreader-inline-fill="" ></rect>
          <rect opacity="0.15" x="43.1626" y="391.623" width="104.222" height="17.3704" fill="#136EF8" data-darkreader-inline-fill=""></rect>
          <path className="color" d="M0 6.3165C0 2.828 2.82799 0 6.3165 0H254.765C258.254 0 261.082 2.82799 261.082 6.3165V157.912H0V6.3165Z" fill={color} data-darkreader-inline-fill=""></path>
          <path className="color" d="M0 453.209H261.082V463.736C261.082 466.643 258.725 469 255.818 469H5.26374C2.35666 469 0 466.643 0 463.736V453.209Z" fill={color} data-darkreader-inline-fill=""></path>
          <path className="color" d="M156.333 400.571C156.333 405.513 152.327 409.52 147.385 409.52C142.443 409.52 138.437 405.513 138.437 400.571C138.437 395.629 142.443 391.623 147.385 391.623C152.327 391.623 156.333 395.629 156.333 400.571Z" fill={color} data-darkreader-inline-fill="" ></path>
          <path className="color" d="M52.1111 400.571C52.1111 405.513 48.1048 409.52 43.1627 409.52C38.2207 409.52 34.2144 405.513 34.2144 400.571C34.2144 395.629 38.2207 391.623 43.1627 391.623C48.1048 391.623 52.1111 395.629 52.1111 400.571Z" fill={color} data-darkreader-inline-fill="" ></path>
          <path opacity="0.2" d="M0 6.84287C0 3.35436 2.82799 0.526367 6.3165 0.526367H254.765C258.254 0.526367 261.082 3.35436 261.082 6.84287V21.5814H0V6.84287Z" fill="#1E1E20" data-darkreader-inline-fill=""></path>
          <g opacity="0.7">
            <path d="M19.4758 10.5275C19.4758 13.7253 16.7656 16.3176 13.4225 16.3176C10.0793 16.3176 7.36914 13.7253 7.36914 10.5275C7.36914 7.32969 10.0793 4.73737 13.4225 4.73737C16.7656 4.73737 19.4758 7.32969 19.4758 10.5275Z" fill="white" data-darkreader-inline-fill="" ></path>
            <path d="M35.267 10.5275C35.267 13.7253 32.6747 16.3176 29.4769 16.3176C26.2791 16.3176 23.6868 13.7253 23.6868 10.5275C23.6868 7.32969 26.2791 4.73737 29.4769 4.73737C32.6747 4.73737 35.267 7.32969 35.267 10.5275Z" fill="white" data-darkreader-inline-fill="" ></path>
            <path d="M51.5846 10.5275C51.5846 13.7253 48.8745 16.3176 45.5313 16.3176C42.1882 16.3176 39.478 13.7253 39.478 10.5275C39.478 7.32969 42.1882 4.73737 45.5313 4.73737C48.8745 4.73737 51.5846 7.32969 51.5846 10.5275Z" fill="white" data-darkreader-inline-fill="" ></path>
            <path d="M71.0367 40.6915C71.0367 39.1493 72.287 37.899 73.8293 37.899H188.24C189.783 37.899 191.033 39.1493 191.033 40.6915C191.033 42.2338 189.783 43.4841 188.24 43.4841H73.8293C72.287 43.4841 71.0367 42.2338 71.0367 40.6915Z" fill="white" data-darkreader-inline-fill="" ></path>
          </g>
          <path d="M172.111 74.2023C172.111 69.8325 175.653 66.29 180.023 66.29H235.273C239.643 66.29 243.185 69.8325 243.185 74.2023C243.185 78.5721 239.643 82.1145 235.273 82.1145H180.023C175.653 82.1145 172.111 78.5721 172.111 74.2023Z" fill="white" data-darkreader-inline-fill="" ></path>
          <path d="M95.4978 74.2023C95.4978 69.8325 99.0402 66.29 103.41 66.29H158.66C163.03 66.29 166.572 69.8325 166.572 74.2023C166.572 78.5721 163.03 82.1145 158.66 82.1145H103.41C99.0402 82.1145 95.4978 78.5721 95.4978 74.2023Z" fill="white" data-darkreader-inline-fill="" ></path>
          <path d="M18.4233 74.2023C18.4233 69.8325 21.9658 66.29 26.3356 66.29H81.5858C85.9556 66.29 89.498 69.8325 89.498 74.2023C89.498 78.5721 85.9556 82.1145 81.5857 82.1145H26.3356C21.9658 82.1145 18.4233 78.5721 18.4233 74.2023Z" fill="white" data-darkreader-inline-fill="" ></path>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.3175 361.093C13.4104 361.093 11.0537 363.45 11.0537 366.357V427.416C11.0537 430.323 13.4104 432.68 16.3175 432.68H244.764C247.671 432.68 250.028 430.323 250.028 427.416V366.357C250.028 363.45 247.671 361.093 244.764 361.093H16.3175ZM22.6341 372.147C21.4713 372.147 20.5286 373.09 20.5286 374.253V414.783C20.5286 415.946 21.4713 416.889 22.6341 416.889H169.493C170.656 416.889 171.598 415.946 171.598 414.783V374.253C171.598 373.09 170.656 372.147 169.493 372.147H22.6341ZM184.231 372.147C183.068 372.147 182.126 373.09 182.126 374.253V414.783C182.126 415.946 183.068 416.889 184.231 416.889H237.395C238.558 416.889 239.501 415.946 239.501 414.783V374.253C239.501 373.09 238.558 372.147 237.395 372.147H184.231Z" fill="white" data-darkreader-inline-fill=""></path>
          <path d="M41.0571 400.045L43.1626 402.15L45.2681 400.045" stroke="white" strokeWidth="1.05275" strokeLinecap="round" sstrokelinejoin="round" data-darkreader-inline-stroke=""></path>
          <path d="M149.49 401.624L147.385 399.519L145.279 401.624" stroke="white" strokeWidth="1.05275" strokeLinecap="round" sstrokelinejoin="round" data-darkreader-inline-stroke=""></path>
          <rect x="11.0537" y="114.75" width="238.448" height="236.869" rx="5.26375" fill="white" data-darkreader-inline-fill=""></rect>
          <g opacity="0.1">
            <path d="M22.6343 137.384C22.6343 134.477 24.9909 132.12 27.898 132.12H71.3898C73.7154 132.12 75.6007 134.005 75.6007 136.331V145.806C75.6007 148.132 73.7154 150.017 71.3898 150.017H27.898C24.9909 150.017 22.6343 147.66 22.6343 144.753V137.384Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M22.6343 174.756C22.6343 171.849 24.9909 169.493 27.898 169.493H71.3898C73.7154 169.493 75.6007 171.378 75.6007 173.704V183.178C75.6007 185.504 73.7154 187.389 71.3898 187.389H27.898C24.9909 187.389 22.6343 185.033 22.6343 182.126V174.756Z" fill="#2C3242" data-darkreader-inline-fill=""></path>
            <path d="M22.6343 212.129C22.6343 209.222 24.9909 206.865 27.898 206.865H71.3898C73.7154 206.865 75.6007 208.751 75.6007 211.076V220.551C75.6007 222.877 73.7154 224.762 71.3898 224.762H27.898C24.9909 224.762 22.6343 222.405 22.6343 219.498V212.129Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M22.6343 249.502C22.6343 246.595 24.9909 244.238 27.898 244.238H71.3898C73.7154 244.238 75.6007 246.123 75.6007 248.449V257.924C75.6007 260.249 73.7154 262.135 71.3898 262.135H27.898C24.9909 262.135 22.6343 259.778 22.6343 256.871V249.502Z" fill="#2C3242" data-darkreader-inline-fill=""></path>
            <path d="M22.6343 324.247C22.6343 321.34 24.9909 318.983 27.898 318.983H71.3898C73.7154 318.983 75.6007 320.869 75.6007 323.194V332.669C75.6007 334.995 73.7154 336.88 71.3898 336.88H27.898C24.9909 336.88 22.6343 334.523 22.6343 331.616V324.247Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M22.6343 286.874C22.6343 283.967 24.9909 281.611 27.898 281.611H71.3898C73.7154 281.611 75.6007 283.496 75.6007 285.822V295.296C75.6007 297.622 73.7154 299.507 71.3898 299.507H27.898C24.9909 299.507 22.6343 297.151 22.6343 294.244V286.874Z" fill="#2C3242" data-darkreader-inline-fill=""></path>
          </g>
          <g opacity="0.1">
            <path d="M48.4263 147.911C48.4263 150.237 46.541 152.122 44.2153 152.122L24.5421 152.122C22.2164 152.122 20.3311 150.237 20.3311 147.911L20.3311 137.91C20.3311 135.585 22.2164 133.699 24.5421 133.699L44.2153 133.699C46.541 133.699 48.4263 135.585 48.4263 137.91V147.911Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M48.4263 185.284C48.4263 187.61 46.541 189.495 44.2153 189.495L24.5421 189.495C22.2164 189.495 20.3311 187.61 20.3311 185.284L20.3311 175.283C20.3311 172.957 22.2164 171.072 24.5421 171.072H44.2153C46.541 171.072 48.4263 172.957 48.4263 175.283V185.284Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M48.4263 222.657C48.4263 224.982 46.541 226.868 44.2153 226.868H24.5421C22.2164 226.868 20.3311 224.982 20.3311 222.657L20.3311 212.655C20.3311 210.33 22.2164 208.444 24.5421 208.444H44.2153C46.541 208.444 48.4263 210.33 48.4263 212.655V222.657Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M48.4263 260.029C48.4263 262.355 46.541 264.24 44.2153 264.24H24.5421C22.2164 264.24 20.3311 262.355 20.3311 260.029L20.3311 250.028C20.3311 247.702 22.2164 245.817 24.5421 245.817H44.2153C46.541 245.817 48.4263 247.702 48.4263 250.028V260.029Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M48.4263 334.774C48.4263 337.1 46.541 338.985 44.2153 338.985H24.5421C22.2164 338.985 20.3311 337.1 20.3311 334.774L20.3311 324.773C20.3311 322.448 22.2164 320.562 24.5421 320.562H44.2153C46.541 320.562 48.4263 322.448 48.4263 324.773V334.774Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M48.4263 297.402C48.4263 299.727 46.541 301.613 44.2153 301.613H24.5421C22.2164 301.613 20.3311 299.727 20.3311 297.402L20.3311 287.401C20.3311 285.075 22.2164 283.19 24.5421 283.19H44.2153C46.541 283.19 48.4263 285.075 48.4263 287.401V297.402Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
          </g>
          <path d="M48.4263 145.806C48.4263 148.132 46.541 150.017 44.2153 150.017L24.5421 150.017C22.2164 150.017 20.3311 148.132 20.3311 145.806L20.3311 135.805C20.3311 133.479 22.2164 131.594 24.5421 131.594L44.2153 131.594C46.541 131.594 48.4263 133.479 48.4263 135.805V145.806Z" fill="white" data-darkreader-inline-fill="" ></path>
          <path d="M48.4263 183.178C48.4263 185.504 46.541 187.389 44.2153 187.389L24.5421 187.389C22.2164 187.389 20.3311 185.504 20.3311 183.178L20.3311 173.177C20.3311 170.852 22.2164 168.966 24.5421 168.966H44.2153C46.541 168.966 48.4263 170.852 48.4263 173.177V183.178Z" fill="white" data-darkreader-inline-fill="" ></path>
          <path d="M48.4263 220.551C48.4263 222.877 46.541 224.762 44.2153 224.762H24.5421C22.2164 224.762 20.3311 222.877 20.3311 220.551L20.3311 210.55C20.3311 208.224 22.2164 206.339 24.5421 206.339H44.2153C46.541 206.339 48.4263 208.224 48.4263 210.55V220.551Z" fill="white" data-darkreader-inline-fill="" ></path>
          <path d="M48.4263 257.924C48.4263 260.249 46.541 262.135 44.2153 262.135H24.5421C22.2164 262.135 20.3311 260.249 20.3311 257.924L20.3311 247.923C20.3311 245.597 22.2164 243.712 24.5421 243.712H44.2153C46.541 243.712 48.4263 245.597 48.4263 247.923V257.924Z" fill="white" data-darkreader-inline-fill="" ></path>
          <path d="M48.4263 332.669C48.4263 334.995 46.541 336.88 44.2153 336.88H24.5421C22.2164 336.88 20.3311 334.995 20.3311 332.669L20.3311 322.668C20.3311 320.342 22.2164 318.457 24.5421 318.457H44.2153C46.541 318.457 48.4263 320.342 48.4263 322.668V332.669Z" fill="white" data-darkreader-inline-fill="" ></path>
          <path d="M48.4263 295.296C48.4263 297.622 46.541 299.507 44.2153 299.507H24.5421C22.2164 299.507 20.3311 297.622 20.3311 295.296L20.3311 285.295C20.3311 282.97 22.2164 281.084 24.5421 281.084H44.2153C46.541 281.084 48.4263 282.97 48.4263 285.295V295.296Z" fill="white" data-darkreader-inline-fill="" ></path>
          <g opacity="0.1">
            <path d="M232.132 142.121C232.132 146.482 228.597 150.017 224.236 150.017C219.875 150.017 216.34 146.482 216.34 142.121C216.34 137.761 219.875 134.226 224.236 134.226C228.597 134.226 232.132 137.761 232.132 142.121Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M232.132 178.967C232.132 183.328 228.597 186.863 224.236 186.863C219.875 186.863 216.34 183.328 216.34 178.967C216.34 174.607 219.875 171.072 224.236 171.072C228.597 171.072 232.132 174.607 232.132 178.967Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M232.132 215.814C232.132 220.174 228.597 223.709 224.236 223.709C219.875 223.709 216.34 220.174 216.34 215.814C216.34 211.453 219.875 207.918 224.236 207.918C228.597 207.918 232.132 211.453 232.132 215.814Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M232.132 252.66C232.132 257.021 228.597 260.556 224.236 260.556C219.875 260.556 216.34 257.021 216.34 252.66C216.34 248.299 219.875 244.764 224.236 244.764C228.597 244.764 232.132 248.299 232.132 252.66Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M232.132 326.352C232.132 330.713 228.597 334.248 224.236 334.248C219.875 334.248 216.34 330.713 216.34 326.352C216.34 321.992 219.875 318.457 224.236 318.457C228.597 318.457 232.132 321.992 232.132 326.352Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M232.132 289.506C232.132 293.867 228.597 297.402 224.236 297.402C219.875 297.402 216.34 293.867 216.34 289.506C216.34 285.146 219.875 281.611 224.236 281.611C228.597 281.611 232.132 285.146 232.132 289.506Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 136.857C87.9048 135.985 88.5171 135.278 89.2725 135.278H202.34C203.095 135.278 203.707 135.985 203.707 136.857C203.707 137.73 203.095 138.437 202.34 138.437H89.2725C88.5171 138.437 87.9048 137.73 87.9048 136.857Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 173.704C87.9048 172.832 88.5171 172.125 89.2725 172.125H202.34C203.095 172.125 203.707 172.832 203.707 173.704C203.707 174.576 203.095 175.283 202.34 175.283H89.2725C88.5171 175.283 87.9048 174.576 87.9048 173.704Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 146.859C87.9048 145.986 88.5171 145.279 89.2725 145.279H202.34C203.095 145.279 203.707 145.986 203.707 146.859C203.707 147.731 203.095 148.438 202.34 148.438H89.2725C88.5171 148.438 87.9048 147.731 87.9048 146.859Z" fill="#2C3242" data-darkreader-inline-fill=""></path>
            <path d="M87.9048 183.705C87.9048 182.833 88.5171 182.126 89.2725 182.126H202.34C203.095 182.126 203.707 182.833 203.707 183.705C203.707 184.577 203.095 185.284 202.34 185.284H89.2725C88.5171 185.284 87.9048 184.577 87.9048 183.705Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 211.603C87.9048 210.731 88.5171 210.024 89.2725 210.024H202.34C203.095 210.024 203.707 210.731 203.707 211.603C203.707 212.475 203.095 213.182 202.34 213.182H89.2725C88.5171 213.182 87.9048 212.475 87.9048 211.603Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 248.449C87.9048 247.577 88.5171 246.87 89.2725 246.87H202.34C203.095 246.87 203.707 247.577 203.707 248.449C203.707 249.321 203.095 250.028 202.34 250.028H89.2725C88.5171 250.028 87.9048 249.321 87.9048 248.449Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 221.604C87.9048 220.732 88.5171 220.025 89.2725 220.025H202.34C203.095 220.025 203.707 220.732 203.707 221.604C203.707 222.476 203.095 223.183 202.34 223.183H89.2725C88.5171 223.183 87.9048 222.476 87.9048 221.604Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 258.45C87.9048 257.578 88.5171 256.871 89.2725 256.871H202.34C203.095 256.871 203.707 257.578 203.707 258.45C203.707 259.322 203.095 260.029 202.34 260.029H89.2725C88.5171 260.029 87.9048 259.322 87.9048 258.45Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 285.295C87.9048 284.423 88.5171 283.716 89.2725 283.716H202.34C203.095 283.716 203.707 284.423 203.707 285.295C203.707 286.167 203.095 286.874 202.34 286.874H89.2725C88.5171 286.874 87.9048 286.167 87.9048 285.295Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 322.141C87.9048 321.269 88.5171 320.562 89.2725 320.562H202.34C203.095 320.562 203.707 321.269 203.707 322.141C203.707 323.014 203.095 323.721 202.34 323.721H89.2725C88.5171 323.721 87.9048 323.014 87.9048 322.141Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 295.296C87.9048 294.424 88.5171 293.717 89.2725 293.717H202.34C203.095 293.717 203.707 294.424 203.707 295.296C203.707 296.168 203.095 296.875 202.34 296.875H89.2725C88.5171 296.875 87.9048 296.168 87.9048 295.296Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
            <path d="M87.9048 332.143C87.9048 331.27 88.5171 330.563 89.2725 330.563H202.34C203.095 330.563 203.707 331.27 203.707 332.143C203.707 333.015 203.095 333.722 202.34 333.722H89.2725C88.5171 333.722 87.9048 333.015 87.9048 332.143Z" fill="#2C3242" data-darkreader-inline-fill="" ></path>
          </g>
        </svg>
      </div>
    )
}

export default MobilePreview
