import PageContainer from '../../components/Charge/PageContainer'
import { ReactComponent as PageIllustration } from '../../images/billing-cancel.svg'
import { ReactComponent as WarningIcon } from '../../images/warning-icon.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'
import { Button, Divider, Radio, Space, Form, Input, Alert, Modal, Typography } from 'antd'
import { useCallback, useRef, useState } from 'react'
import { useFetch } from '../../hooks/requests'
import useCustomHistory from '../../hooks/useCustomHistory'
import { isDebug } from '../../lib/debug'

const { Title } = Typography

function Header() {
  return <div><Logo /></div>
}

const reasons = {
  reliability: 'Reliability',
  functionality: 'Functionality / Missing feature',
  incompleteCompliance: 'Incomplete Compliance',
  price: 'Price',
  other: 'Other',
}

function ForceCancelBlock({ action }) {
  const history = useCustomHistory()
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Plan cancellation"
    action={<>
      <Button
        size="large"
        type="default"
        onClick={() => history.push('/billing/cancelled')}
      >
        Just show
      </Button>
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Force
      </Button>
    </>}
  />
}

function Content({ primaryAction = () => null, updateShop, back }) {
  const [request/* , { loading, error, data  }*/] = useFetch()
  const history = useCustomHistory()
  const [showConfirm, setShowConfirm] = useState(false)
  const [form] = Form.useForm()
  const formData = useRef({})
  const [cancelling, setCancelling] = useState(false)

  const cancelSubscription = useCallback(async (values) => {
    setCancelling(true)
    // TODO: update state only
    updateShop({
      planCancellationFeedback: formData.current,
      onboardingPassedStage: null,
      // pricingPlan: null,
      // pricingPlanPeriod: null,
      setupCompleted: false,
      isActive: false,
      subscriptionIssue: false,
    })
    const result = await request(
      'default/cancelSubscription',
      'POST',
      formData.current,
    )
    setShowConfirm(false)
    console.log('TCL ~ file: PricingPlans.jsx ~ line 96 ~ cancelSubscription ~ result', result)
    history.push('/billing/cancelled')
  }, [history, request, updateShop])

  const onFinish = useCallback((data) => {
    formData.current = data
    setShowConfirm(true)
  }, [])

  return <div className="rows" style={{ width: '730px' }}>
    <Title>
      What could we have done <span className="blue-text">better?</span>
    </Title>
    <div className="text-14" style={{ color: '#5D5D5D' }}>
      We're sorry that you have decided to stop using our service.
      It would really help us out a ton if you could tell us why you decided to stop using XCompliant.
    </div>
    <Divider style={{ margin: 0 }} />
    <Form
       form={form}
       layout="vertical"
       style={{ width: '500px', marginBottom: '-2em' }}
       onFinish={onFinish}
       autoComplete="off"
       //  initialValues={{ requiredMarkValue: requiredMark }}
      //  onValuesChange={onRequiredTypeChange}
       requiredMark={false}
    >
      <Form.Item
        label="Which of the following reasons best describe why you're cancelling XCompliant?"
        name="reason"
        rules={[{ required: true, message: 'Please choose your reason!' }]}
      >
        <Radio.Group
          // onChange={onChange}
          // value={value}
        >
          <Space direction="vertical">
            {Object.entries(reasons).map(([value, title]) => <Radio value={value}>{title}</Radio>)}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Could you please tell us a bit more details?"
        name="details"
        rules={[{ required: true, message: 'Please input details!' }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="Would you like us to contact you about your feedback?"
        name="contact"
        rules={[{ required: true, message: 'Please choose contact preference!' }]}
      >
        <Radio.Group
          // onChange={onChange}
          // value={value}
        >
          <Space direction="vertical">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Space>
        <Button type="primary" size="large" onClick={back}>
          Go Back
        </Button>
        <Button type="default" size="large">
          Get help from Support
        </Button>
        <Button type="default" size="large" htmlType="submit" >
          Continue with cancellation
        </Button>
      </Space>
    </Form>
    <Modal
      // title="Vertically centered modal dialog"
      centered
      width={730}
      visible={showConfirm}
      // onOk={() => cancelSubscription()}
      onCancel={() => setShowConfirm(false)}
      footer={null}
    >
      <div className="rows rows-align-center" style={{ margin: '40px 0' }}>
        <WarningIcon />
        <div className="text-30" >Are you sure you want to cancel the plan?</div>
        <Space>
          <Button
            type="default"
            htmlType="submit"
            loading={cancelling}
            disabled={cancelling}
            onClick={() => cancelSubscription()}
          >Cancel my plan</Button>
          <Button
            type="primary"
            onClick={() => setShowConfirm(false)}
            disabled={cancelling}
          >No, go back</Button>
        </Space>
      </div>
    </Modal>
  </div>
}

function Cancel ({ primaryAction, forceCancel, updateShop, back }) {
  return (
    <PageContainer
      header={<Header />}
      leftBlock={<>
        <Content primaryAction={primaryAction} updateShop={updateShop} back={back} />
        {isDebug() && <ForceCancelBlock cation={forceCancel} />}
      </>}
      rightBlock={(<PageIllustration />)}
    />
  )
}

export default Cancel
