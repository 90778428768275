import PageContainer from '../components/Charge/PageContainer'
import { ReactComponent as PageIllustration } from '../images/activation-failed.svg'
import { ReactComponent as PageIcon } from '../images/activation-failed-icon.svg'
import { ReactComponent as Logo } from '../images/logo.svg'
import { Alert, Button, Typography } from 'antd'
import { isDebug } from '../lib/debug'

const { Title, Paragraph } = Typography

function Header() {
  return <div><Logo /></div>
}

function BackBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Back to Analyze page"
    action={
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Back
      </Button>
    }
  />
}

function Content({ primaryAction = () => null }) {
  return <div
    className="activation-failed-content"
    style={{ width: '480px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '2em' }}
  >
    <PageIcon />
    <Title>
      Activation Failed
    </Title>
    <Paragraph style={{ textAlign: 'center' }}>
      Something went wrong during the activation of your plan.
      Don't worry! We'll fix this as soon as possible for you. Please contact our support team.
    </Paragraph>
    <div className="page-button" style={{ display: 'flex', gap: '20px' }}>
      <Button type="primary" size="large" href={process.env.REACT_APP_CONTACT_US_URL} target="_blank">
        Contact Support
      </Button>
    </div>
  </div>
}

function ActivationFailed({ primaryAction, setActivationFailed }) {
  return (
    <PageContainer
      header={<Header />}
      leftBlock={<>
        <Content primaryAction={primaryAction} />
        {isDebug() && <BackBlock action={() => setActivationFailed(false)} />}
      </>}
      rightBlock={(<PageIllustration />)}
    />
  )
}

export default ActivationFailed
