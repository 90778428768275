import { Alert, Button, Card, Switch, Typography } from 'antd'
import { useCallback } from 'react'
import { isDebug } from '../../lib/debug'
// import useCustomHistory from '../../hooks/useCustomHistory'
import './Dashboard.scss'

const { Paragraph } = Typography

function SkipTrialBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Skip trial period (Display Billing page)"
    action={
      <Button
        size="large"
        type="default"
        onClick={() => {
          action()
        }}
      >
        Go
      </Button>
    }
  />
}

function Dashboard({ shop, _switch, trialExpired, updateShop }) {

  const onSwitchChange = useCallback(() => {
    _switch()
  }, [_switch])

  return (
    <div className="dashboard"><div>
      <div className="dashboardContent">
        <Card>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 40, fontSize: 16 }}>
              <strong>Status</strong>
            </div>
            <Switch
              // defaultChecked
              onChange={onSwitchChange}
              checked={shop.isActive}
            />
            <div style={{ marginLeft: '1em', fontSize: 14 }}>
              {shop.isActive ? <span style={{ color: '#0056D2' }}>Enable</span> : <span style={{ color: '#637381' }}>Disable</span>}
            </div>
          </div>
        </Card>
        {shop.isActive
          ? (
            <Paragraph>
              Great! XCompliant is currently <span style={{ color: '#04C415', fontWeight: 'bold' }}>enabled</span>.
            </Paragraph>
          )
          : (<>
            <Paragraph>
              Watch out! XCompliant is currently <span style={{ color: '#D41717', fontWeight: 'bold' }}>disabled</span>.
              Enable the "Status" switch right over this text to enable our XCompliant on your site.
            </Paragraph>
            <Paragraph>
              Contact our
              {' '}
              <a
                target="_blank"
                href={process.env.REACT_APP_CONTACT_US_URL}
                rel="noreferrer"
              >
                Contact Support
              </a>
              {' '}
              team if you're having trouble.
            </Paragraph>
          </>)
        }
        <div>
          <Button href={process.env.REACT_APP_CONTACT_US_URL} target="_blank">
            Contact Support
          </Button>
        </div>
      </div>
      {(isDebug() && shop.pricingPlan && !trialExpired) && <SkipTrialBlock
        action={() => {
          updateShop({ trialStartedAt: (new Date() - 6 * 24 * 60 * 60 * 1000) })
        }}
      />}
    </div></div>
  )

}

export default Dashboard
