import PageContainer from '../../components/Charge/PageContainer'
import { ReactComponent as PageIllustrationSupport } from '../../images/onboarding-domain-support.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'
import { Alert, Button, Input, Typography } from 'antd'
import { useState } from 'react'
import OnboardingSteps from '../../components/OnboardingSteps'
import TokenFailed from '../TokenFailed'
import { isDebug } from '../../lib/debug'

const { Title, Paragraph, Text } = Typography

function Header() {
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Logo />
    <OnboardingSteps current={0} />
  </div>
}

function Content({ primaryAction = () => null, secondaryAction = () => null, shop }) {
  return <>
    <Title>
      Let's validate your <span className="blue-text">domain</span>
    </Title>
    <Paragraph>
      Is this the correct domain on which you're looking to activate XCompliant?
    </Paragraph>
    <div style={{ width: '216px' }}><Input className="domain-input" disabled defaultValue={shop?.shopify_domain} /></div>
    <div className="page-button" style={{ display: 'flex', gap: '20px' }}>
      <Button type="primary" size="large" onClick={primaryAction}>
        Yes, this is the correct domain
      </Button>
      <Button type="default" size="large" onClick={secondaryAction}>
        No, I would like to activate one or more domains
      </Button>
    </div>
    <Paragraph>
      <Text>Need help?</Text>
      {' '}
      <a
        target="_blank"
        href={process.env.REACT_APP_CONTACT_US_URL}
        rel="noreferrer"
      >
        Contact Support
      </a>
    </Paragraph>
  </>
}

function TokenFailedBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Show Token Failed Screen"
    action={
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Show
      </Button>
    }
  />
}

function ContentSupport({ secondaryAction }) {
  return <>
    <Title style={{ maxWidth: 612 }}>
      We understand you'd like to enable <span className="blue-text">XCompliant</span> on one or multiple other domains.
    </Title>
    <Paragraph style={{ maxWidth: 730 }}>
      We'll be more than happy to help. Please click the button below to reach out and our support team will be more than happy to help you as soon as possible with your request.
    </Paragraph>
    <div className="page-button" style={{ display: 'flex', gap: '20px' }}>
      <Button type="primary" size="large" href={process.env.REACT_APP_CONTACT_US_URL} target="_blank" >
        Contact Support
      </Button>
      <Button type="default" size="large" onClick={secondaryAction}>
        Go back
      </Button>
    </div>
  </>
}

function VerifyDomain ({ primaryAction, shop }) {
  const [support, setSupport] = useState(false)
  const [tokenFailed, setTokenFailed] = useState(false)

  if (tokenFailed) {
    return (
      <TokenFailed backAction={() => setTokenFailed(false)} />
    )
  }

  if (support) {
    return (
      <PageContainer
        header={<Header />}
        leftBlock={<>
          <ContentSupport secondaryAction={() => setSupport(false)} />
        </>}
        rightBlock={(<PageIllustrationSupport />)}
      />
    )
  }

  return (
    <PageContainer
      header={<Header />}
      leftBlock={<>
        <Content shop={shop} primaryAction={primaryAction} secondaryAction={() => setSupport(true)} />
        {isDebug() && <TokenFailedBlock action={() => setTokenFailed(true)} />}
      </>}
      rightBlock={(<img src={`${process.env.REACT_APP_PUBLIC_URL}/images/onboarding-domain.svg`} alt="" />)}
    />
  )
}

export default VerifyDomain
