import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from './containers/Dashboard/Dashboard'
import SubscriptionIssue from './containers/SubscriptionIssue'
import WaitingPage from './containers/WaitingPage'
import Settings from './containers/Settings/Settings'
import { useGetShop, useUpdateShop } from './hooks/requests'
import TabMenu from './components/TabMenu'
import PricingPlans from './containers/PricingPlans'
import useCustomHistory from './hooks/useCustomHistory'
import Onboarding from './containers/Onboarding/Onboarding'
import PageContainer from './components/Charge/PageContainer'
import { ReactComponent as DashboardPageIllustration } from './images/dashboard.svg'
import { ReactComponent as Logo } from './images/logo.svg'
import PaymentMethod from './containers/Billing/PaymentMethod'
import Invoices from './containers/Billing/Invoices'
import Cancel from './containers/Billing/Cancel'
import Cancelled from './containers/Billing/Cancelled'
import DebugContext from './context/DebugContext'
import ResetBlock from './components/ResetBlock'
import PaymentFailed from './containers/PaymentFailed'
import useCustomLocation from './hooks/useCustomLocation'
import { initDebug, isDebug } from './lib/debug'

const tabs = [
  {
    key: '/',
    label: 'Dashboard',
    // hide: false,
  },
  {
    key: '/settings',
    label: 'Settings',
  },
  {
    key: '/billing-group',
    label: 'Billing',
    children: [
      {
        key: '/billing',
        label: 'Plan Selection',
      },
      {
        key: '/billing/payment-method',
        label: 'Payment Method',
      },
      {
        key: '/billing/invoices',
        label: 'Invoices',
      },
    ],
  },
  {
    key: 'help',
    external: 'yes',
    label: (
      <a href="https://help.xcompliant.com/help" target="_blank" rel="noopener noreferrer">
        Help
      </a>
    ),
  },
]

function Header() {
  return <div><Logo /></div>
}

function RouterComponent(props) {
  const [getShop, { data, error }] = useGetShop()
  const [updateShop, { loading }] = useUpdateShop()
  const [shop, setShop] = useState(null)
  // const [changePlan] = useChangePlan()
  const { push } = useCustomHistory()
  const location = useCustomLocation()

  useEffect(() => {
    getShop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG_MODE) {
      initDebug()
    }
    if (data) {
      setShop(data)
      // if (!data.pricingPlan && data.trialStartedAt && !data.noTrialRedirectedToPayment) {
      //   // changePlan('Premium')
      // }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const _switch = useCallback(() => {
    updateShop({ isActive: !shop.isActive })
    setShop(_shop => ({ ..._shop, isActive: !_shop.isActive }))
  }, [shop, updateShop])

  const updateShopAction = useCallback((data) => {
    updateShop(data)
    setShop(_shop => ({ ..._shop, ...data }))
  }, [updateShop])

  const resetAction = useCallback(async () => {
    await updateShop({
      pricingPlan: null,
      pricingPlanPeriod: null,
      setupCompleted: false,
      isActive: false,
      subscriptionIssue: false,
      onboardingPassedStage : null,
    })
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }, [updateShop])

  if (error) {
    return <>error: 12{error.code || 600}</>
  }

  // if (!shop || (!shop.pricingPlan && shop.trialStartedAt && !shop.noTrialRedirectedToPayment)) {
  if (!shop) {
    return <>loading</>
  }

  // TODO:
  if (shop.subscriptionIssue || (!shop.pricingPlan && shop.noTrialRedirectedToPayment)) {
    return <SubscriptionIssue
      skip={() => {
        updateShop({ subscriptionIssue: false })
        setShop(shop => ({ ...shop, subscriptionIssue: false }))
      }}
    />
  }

  if (location.pathname === '/billing/failed') {
    return <PaymentFailed />
  }
  if (location.pathname === '/billing/cancelled') {
    return <DebugContext.Provider value={{ reset: resetAction }}><Cancelled /></DebugContext.Provider>
  }

  if (!shop.setupCompleted) {
    return <DebugContext.Provider value={{ reset: resetAction }}>
      <Onboarding usedTrial={!!shop.trialStartedAt} shop={shop} updateShop={updateShopAction}/>
      {isDebug() && (
        <div style={{ marginTop: shop.onboardingPassedStage === 'agreement' ? '0' : '-84px' }}><ResetBlock /></div>
      )}
    </DebugContext.Provider>
  }

  let trialExpired = false
  if (shop.trialStartedAt) {
    try {
      trialExpired = new Date() - new Date(shop.trialStartedAt) > 5 * 24 * 60 * 60 * 1000
    } catch (err) {
      //
    }
  }

  if (!shop.setupCompleted) {
    return <WaitingPage
      shop={shop}
      skip={() => {
        updateShop({ setupCompleted: true })
        setShop(shop => ({ ...shop, setupCompleted: true }))
      }}
      updateEmail={(email) => {
        updateShop({ contactEmail: email })
        setShop(shop => ({ ...shop, email }))
      }}
    />
  }

  if (location.pathname === '/billing/cancel') {
    return <DebugContext.Provider value={{ reset: resetAction }}>
      <Cancel
        forceCancel={() => {
          updateShopAction({
            pricingPlan: null,
            pricingPlanPeriod: null,
            setupCompleted: false,
            isActive: false,
            subscriptionIssue: false,
            onboardingPassedStage : 'domain',
          })
          push('/billing/cancelled')
        }}
        back={() => push('/billing')}
        updateShop={(data) => {
          updateShopAction(data)
          push('/')
        }}
      />
    </DebugContext.Provider>
  }

  const RegularPage = <div style={{ backgroundColor: '#F4F6F8' }}><DebugContext.Provider value={{ reset: resetAction }}>
    <TabMenu tabs={tabs} />
    <div style={{ padding: '2em' }}>
      {location.pathname === '/' && <Dashboard shop={shop} _switch={_switch} trialExpired={trialExpired} updateShop={updateShopAction} />}
      {location.pathname === '/onboarding' && <Onboarding />}
      {location.pathname === '/billing' && <PricingPlans shop={shop} usedTrial={!!shop.trialStartedAt} />}
      {location.pathname === '/billing/payment-method' && <PaymentMethod shop={shop} />}
      {location.pathname === '/billing/invoices' && <Invoices />}
      {location.pathname === '/settings' && <div>
        <Settings shop={shop} updateShop={async (data) => {
          await updateShop(data)
          getShop()
        }} saving={loading} />
      </div>}
      {/* <Switch>
        <Route exact path="/">
          <Dashboard shop={shop} _switch={_switch} />
        </Route>
        <Route exact path="/billing">
          <PricingPlans shop={shop} usedTrial={!!shop.trialStartedAt} />
        </Route>
        <Route path="/settings">
          <Page>
            <Settings shop={shop} updateShop={async (data) => {
              await updateShop(data)
              getShop()
            }} saving={loading} />
          </Page>
        </Route>
      </Switch> */}
    </div>
    {isDebug() && <ResetBlock />}
  </DebugContext.Provider></div>

  return <>
    <PageContainer
      header={<Header />}
      leftBlock={RegularPage}
      rightBlock={(location.pathname === '/' ? <DashboardPageIllustration /> : null)}
    />
  </>

}

export default RouterComponent
