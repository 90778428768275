import PageContainer from '../components/Charge/PageContainer'
import { ReactComponent as PageIllustration } from '../images/payment-fail.svg'
import { ReactComponent as PageIcon } from '../images/payment-fail-icon.svg'
import { ReactComponent as Logo } from '../images/logo.svg'
import Button from 'antd/lib/button/button'
import useCustomHistory from '../hooks/useCustomHistory'
import { Alert, Typography } from 'antd'
import { isDebug } from '../lib/debug'

const { Title } = Typography

function Header() {
  return <div><Logo /></div>
}

function BackBlock() {
  const history = useCustomHistory()
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Go Back (Onboarding or Dashboard)"
    action={
      <Button
        size="large"
        type="default"
        onClick={() => {
          history.push('/')
        }}
      >
        Go
      </Button>
    }
  />
}

function Content({ primaryAction = () => null }) {
  return <div style={{ width: '480px', display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
    <PageIcon />
    <Title style={{ marginTop: '0' }}>
      Payment Failed
    </Title>
    <div className="text-14" style={{ color: '#5D5D5D' }}>
      Something went wrong while we attempted to process your payment.
      Please check with your payment provider and try again
      or go to our contact support
    </div>
    <div className="page-button" style={{ marginTop: '0', display: 'flex', gap: '20px' }}>
      <Button type="primary" size="large" onClick={primaryAction}>
        Try Again
      </Button>
      <Button type="default" size="large" href={process.env.REACT_APP_CONTACT_US_URL} target="_blank">
        Contact Support
      </Button>
    </div>
    {isDebug() && <BackBlock />}
  </div>
}

function PaymentFail ({ primaryAction }) {
  return (
    <PageContainer
      header={<Header />}
      leftBlock={<Content primaryAction={primaryAction} />}
      rightBlock={(<PageIllustration />)}
    />
  )
}

export default PaymentFail
