import PageContainer from '../components/Charge/PageContainer'
import { ReactComponent as PageIllustration } from '../images/token-failed.svg'
import { ReactComponent as PageIcon } from '../images/activation-failed-icon.svg'
import { ReactComponent as Logo } from '../images/logo.svg'
import { Button, Alert, Typography } from 'antd'
import { isDebug } from '../lib/debug'

const { Title, Paragraph } = Typography

function Header() {
  return <div><Logo /></div>
}

function BackBlock({ action }) {
  return <Alert
    type="warning"
    showIcon
    message="Debug Mode"
    description="Go Back"
    action={
      <Button
        size="large"
        type="default"
        onClick={action}
      >
        Go
      </Button>
    }
  />
}

function Content({ primaryAction = () => null }) {
  return <div className="rows rows-align-center" style={{ width: '480px' }}>
    <PageIcon />
    <Title>
      Token Failed
    </Title>
    <Paragraph>
      Something went wrong while trying to authenticate you.
      This may be caused by a new WordPress installation for example.
      Please contact our support team so that we can sort this out for you.
    </Paragraph>
    <div className="page-button" style={{ display: 'flex', gap: '20px' }}>
      <Button type="primary" size="large" href={process.env.REACT_APP_CONTACT_US_URL} target="_blank">
        Contact Support
      </Button>
    </div>
  </div>
}

function TokenFailed({ primaryAction, backAction }) {
  return (
    <PageContainer
      header={<Header />}
      leftBlock={<>
        <Content primaryAction={primaryAction} />
        {isDebug() && <BackBlock action={backAction} />}
      </>}
      rightBlock={(<PageIllustration />)}
    />
  )
}

export default TokenFailed
